<template lang="pug">
.page
  .leftContainer
    com-theme
    com-bottomBar
  el-card.rightContainer(:body-style='{ padding: "0px" }')
    template(#header)
      .headerContainer
        span 预览
        el-button(type="primary" @click="saveConfig") 保存
    com-preview
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import comTheme from './__com__/theme.vue'
import comBottomBar from './__com__/bottomBar.vue'
import comPreview from './__com__/preview.vue'
import { appConfig } from './__com__/provider'

const { fetch, message } = inject('global')

function getConfig() {
  fetch.get('/config/appConfig/value').then(res => {
    if (res) {
      if (res.theme) {
        appConfig.value.theme = res.theme
      }
      if (res.bottomBar) {
        appConfig.value.bottomBar = res.bottomBar
      }
    }
  })
}

function saveConfig() {
  fetch.put('/config/appConfig/value', { value: appConfig.value }).then(res => {
    message.success('保存成功')
    getConfig()
  })
}

onMounted(() => {
  getConfig()
})
</script>

<style lang="less" scoped>
.headerContainer {
  display: flex;
  justify-content: space-between;
}
.page {
  display: flex;
  align-items: flex-start;
  .leftContainer {
    flex-grow: 1;
    margin-right: 20px;
    .el-card {
      margin-bottom: 20px;
    }
  }
  .rightContainer {
    flex-grow: 0;
    flex-shrink: 0;
    width: 470px;
  }
}
</style>
