<template lang="pug">
el-card(header="小程序配置")
  el-form(label-width="8em" inline)
    el-form-item(label="默认主题色")
      el-color-picker(size="large" v-model="appConfig.theme.primaryColor")
    el-form-item(label="成功颜色")
      el-color-picker(size="large" v-model="appConfig.theme.successColor")
    el-form-item(label="警告颜色")
      el-color-picker(size="large" v-model="appConfig.theme.warningColor")
    el-form-item(label="错误颜色")
      el-color-picker(size="large" v-model="appConfig.theme.dangerColor")
  el-form(label-width="8em" inline)
    el-form-item(label="默认文本色")
      el-color-picker(size="large" v-model="appConfig.theme.textColor")
    el-form-item(label="标题文本色")
      el-color-picker(size="large" v-model="appConfig.theme.textColorTitle")
    el-form-item(label="描述文本色")
      el-color-picker(size="large" v-model="appConfig.theme.textColorDesc")
  el-form(label-width="8em" inline)
    el-form-item(label="边框颜色")
      el-color-picker(size="large" v-model="appConfig.theme.borderColor")
    el-form-item(label="背景颜色")
      el-color-picker(size="large" v-model="appConfig.theme.backgroundColor")
  el-form(label-width="8em" inline)
    el-form-item(label="默认文本字号")
      el-input-number(v-model="appConfig.theme.textSize" :step="2")
    el-form-item(label="标题文本字号")
      el-input-number(v-model="appConfig.theme.textSizeTitle" :step="2")
    el-form-item(label="描述文本字号")
      el-input-number(v-model="appConfig.theme.textSizeDesc" :step="2")
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

import { appConfig } from './provider'

const foo = ref('bar')
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
