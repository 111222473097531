<template lang="pug">
.previewContainer
  .mockPage(:style="{'--color-theme':appConfig.theme.primaryColor,'--color-text':appConfig.theme.textColor,'--size-text':appConfig.theme.textSize+'px',background:appConfig.theme.backgroundColor}")
    .title(:style="{background:appConfig.theme.primaryColor}") 默认主题色
    .title(:style="{background:appConfig.theme.successColor}") 成功颜色
    .title(:style="{background:appConfig.theme.warningColor}") 警告颜色
    .title(:style="{background:appConfig.theme.dangerColor}") 错误颜色
    .content(:style="{color: appConfig.theme.textColor,borderColor:appConfig.theme.borderColor,fontSize: appConfig.theme.textSize + 'px'}")
      div 默认文本色 {{ appConfig.theme.textColor }}
      div 默认文本字号 {{ appConfig.theme.textSize }}
    .content(:style="{ color: appConfig.theme.textColorTitle, borderColor: appConfig.theme.borderColor, fontSize: appConfig.theme.textSizeTitle + 'px' }")
      div 标题文本色 {{ appConfig.theme.textColorTitle }}
      div 标题文本字号 {{ appConfig.theme.textSizeTitle }}
    .content(:style="{ fontSize: appConfig.theme.textSizeDesc + 'px', borderColor: appConfig.theme.borderColor, color: appConfig.theme.textColorDesc }")
      div 描述文本色 {{ appConfig.theme.textColorDesc }}
      div 描述文本字号 {{ appConfig.theme.textSizeDesc }}
    .content(:style="{ borderColor: appConfig.theme.borderColor }")
      div 边框颜色 {{ appConfig.theme.borderColor }}
  .bottomBar(:style="{'--color':appConfig.bottomBar.color,borderColor:appConfig.bottomBar.borderColor}")
    .item(v-for="(i,n) in appConfig.bottomBar.list" @click="activeIndex = n" :class="{ isActive: activeIndex === n }" :style="{flexDirection:appConfig.bottomBar.flexDirection}")
      img.icon(:src="i.icon" :style="{width:appConfig.bottomBar.iconSize+'px',height:appConfig.bottomBar.iconSize+'px',borderRadius:appConfig.bottomBar.radius+'px'}")
      .name(:style="{ color: appConfig.bottomBar.nameColor, fontSize: appConfig.bottomBar.nameSize + 'px' }") {{ i.name }}
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { appConfig } from './provider'

const activeIndex = ref(-1)
</script>

<style lang="less" scoped>
.mockPage {
  --color-theme: var(--color-primary);
  --color-text: #333;
  padding: 20px;
  font-size: var(--size-text);
  height: 100%;
  .title {
    background: var(--color-theme);
    color: white;
    padding: 10px;
    text-align: center;
  }
  .content {
    color: var(--color-text);
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    background: white;
    border: 1px solid var(--color-border);
    line-height: 2;
  }
}
.previewContainer {
  width: 100%;
  height: 800px;
  position: relative;
  .bottomBar {
    --color: var(--color-primary);
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 30px;
    border: 1px solid var(--color);
    background: white;
    z-index: 100;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    box-shadow: 1px 1px 6px -2px var(--color);
    .item {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1px solid var(--color);
      padding: 5px;
      .icon {
        width: 20px;
        height: 20px;
        margin: 5px;
      }
      &:last-child {
        border-right: none;
      }
      &.isActive {
        background: var(--color);
        color: white;
      }
    }
  }
}
</style>
