import { ref, computed } from 'vue'

export let appConfig = ref({
  theme: {
    primaryColor: '',
    successColor: '',
    warningColor: '',
    dangerColor: '',
    textColor: '',
    textColorTitle: '',
    textColorDesc: '',
    borderColor: '',
    backgroundColor: '',
    textSize: 14,
    textSizeTitle: 14,
    textSizeDesc: 14
  },
  bottomBar: {
    color: '',
    borderColor: '',
    flexDirection: 'row',
    isShowIcon: true,
    iconSize: 20,
    iconRadius: 5,
    isShowName: true,
    nameColor: '',
    nameSize: 12,
    list: []
  }
})
