<template lang="pug">
el-card
  template(#header)
    .headerContainer
      span 底部菜单配置
  el-form(label-width="8em" inline)
    el-form-item(label="主题色")
      el-color-picker(size="large" v-model="appConfig.bottomBar.color")
    el-form-item(label="边框颜色")
      el-color-picker(size="large" v-model="appConfig.bottomBar.borderColor")
  el-form(label-width="8em")
    el-form-item(label="排列方式")
      el-radio-group(v-model="appConfig.bottomBar.flexDirection")
        el-radio-button(label="row") 横排
        el-radio-button(label="column") 竖排
    el-form-item(label="显示图标")
      el-switch(v-model="appConfig.bottomBar.isShowIcon")
  el-form(label-width="8em" inline)
    el-form-item(label="图标尺寸")
      el-input-number(v-model="appConfig.bottomBar.iconSize")
    el-form-item(label="图标圆角")
      el-input-number(v-model="appConfig.bottomBar.iconRadius")
  el-form(label-width="8em")
    el-form-item(label="显示名称")
      el-switch(v-model="appConfig.bottomBar.isShowName")
  el-form(label-width="8em" inline)
    el-form-item(label="名称颜色")
      el-color-picker(size="large" v-model="appConfig.bottomBar.nameColor")
    el-form-item(label="名称字号")
      el-input-number(v-model="appConfig.bottomBar.nameSize" :step="2")
  el-form(label-width="8em")
    el-form-item(label="菜单项")
      .bottomBarList
        .item(v-for="(i,n) in appConfig.bottomBar.list"  @dragstart="onDragStart($event,n)" @dragend="onDragEnd($event,n)" @dragover="onDragOver($event,n)" :class="{isDrag:currentDragItemIndex===n,isDragOver:currentDragOverItemIndex===n&&currentDragItemIndex!==n}")
          img.icon(:src="i.icon")
          .name {{i.name}}
          .actionBar
            el-button(icon="el-icon-edit" circle plain size="mini" type="primary" @click="handleEditItem(i,n)")
            el-button(icon="el-icon-delete" circle plain size="mini" type="danger" @click="handleDeleteItem(n)")
        .item.additem(@click="handleAdd")
          i.el-icon-plus

  el-dialog(v-model="isShowEdit")
    el-form.editContainer(label-width="6em")
      el-form-item(label="图标")
        el-input(v-model="editItemData.icon")
        .image(v-if="editItemData.icon" v-bg.cover="editItemData.icon")
        //- .addImage(v-else @click="pickImage")
          i.el-icon-upload
      el-form-item(label="名称")
        el-input(v-model="editItemData.name")
      el-form-item(label="类型")
        el-radio-group(v-model="editItemData.type")
          el-radio-button(label="page") 页面
      el-form-item(label="链接")
        el-input(v-model="editItemData.link")
      el-form-item
        el-button(type="primary" @click="handleSubmitEdit") 保存
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

import { appConfig } from './provider'

let isShowEdit = ref(false)
let editItemIndex = ref(-1)
let editItemData = ref({
  icon: '',
  name: '',
  type: 'page',
  link: ''
})

function handleAdd() {
  editItemIndex.value = -1
  editItemData.value = { icon: '', name: '', type: 'page', link: '' }
  isShowEdit.value = true
}
function handleEditItem(val, n) {
  editItemData.value = { ...val }
  editItemIndex.value = n
  isShowEdit.value = true
}
function handleDeleteItem(n) {
  appConfig.value.bottomBar.list.splice(n, 1)
}

function handleSubmitEdit() {
  if (editItemIndex.value < 0) {
    appConfig.value.bottomBar.list.push({
      ...editItemData.value
    })
  } else {
    appConfig.value.bottomBar.list[editItemIndex.value] = {
      ...editItemData.value
    }
  }
  isShowEdit.value = false
}

function pickImage() {
  window.$globalProp.util.pickImageList({ type: 'system', count: 1 }).then(resultList => {
    if (!resultList.length) return
    editItemData.value.icon = resultList[0]
  })
}

let currentDragItemIndex = ref(-1)
let currentDragOverItemIndex = ref(-1)
function onDragStart(e, n) {
  currentDragItemIndex.value = n
}
function onDragEnd(e, n) {
  const sourceItem = appConfig.value.bottomBar.list[currentDragItemIndex.value]
  appConfig.value.bottomBar.list.splice(currentDragItemIndex.value, 1)
  if (currentDragOverItemIndex.value === 0) {
    appConfig.value.bottomBar.list.unshift(sourceItem)
  } else if (currentDragOverItemIndex.value > currentDragItemIndex.value) {
    appConfig.value.bottomBar.list.splice(currentDragOverItemIndex.value - 1, 0, sourceItem)
  } else {
    appConfig.value.bottomBar.list.splice(currentDragOverItemIndex.value, 0, sourceItem)
  }
  currentDragItemIndex.value = -1
  currentDragOverItemIndex.value = -1
}
function onDragOver(e, n) {
  currentDragOverItemIndex.value = n
}
</script>

<style lang="less" scoped>
.bottomBarList {
  display: flex;
  flex-wrap: wrap;
  width: 470px;
  .item {
    text-align: center;
    margin: 5px;
    padding: 5px 10px;
    border: 1px dashed var(--color-border);
    position: relative;
    user-select: none;
    cursor: move;
    &:hover {
      border: 1px dashed var(--color-primary);
      .actionBar {
        display: flex;
      }
    }
    .icon {
      width: 90px;
      height: 90px;
      border-radius: 0;
      background: var(--color-background);
    }
    .actionBar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      display: none;
      justify-content: space-around;
      padding: 5px;
      cursor: default;
    }
    &.additem {
      height: 110px;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      border: 1px solid var(--color-green);
      cursor: pointer;
    }
    &.isDrag {
      opacity: 0.4;
    }
    &.isDragOver {
      overflow: unset;
      margin-left: 70px;
      &:before {
        content: '';
        display: block;
        width: 50px;
        background: var(--color-background);
        border: 1px dashed var(--color-primary);
        border-radius: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -70px;
      }
    }
  }
}

.editContainer {
  .image,
  .addImage {
    width: 100px;
    height: 100px;
  }
  .addImage {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    border: 1px solid var(--color-green);
  }
}
</style>
